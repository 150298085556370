import React, { Suspense } from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// assets
import video1 from '../../../assets/videos/alex.mp4'
import video2 from '../../../assets/videos/hassan.mp4'
import video3 from '../../../assets/videos/andrea.mp4'
import video4 from '../../../assets/videos/kindness.mp4'
import video5 from '../../../assets/videos/edmund.mp4'
import video6 from '../../../assets/videos/mark.mp4'
import video7 from '../../../assets/videos/mohsen.mp4'
import video8 from '../../../assets/videos/qoosim.mp4'
import video9 from '../../../assets/videos/ramkumar.mp4'
import video10 from '../../../assets/videos/samana.mp4'
import video11 from '../../../assets/videos/shaibu.mp4'
import video12 from '../../../assets/videos/shruti.mp4'
import video13 from '../../../assets/videos/ushindi.mp4'
import video14 from '../../../assets/videos/wladimir.mp4'

const Video = React.lazy(() => import('../../common/Video'));

export default function TestimonialsSlider() {
    var settings = {
        dots: false,
        arrows:true,
        infinite: true,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 1224,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 1,
            }
          },
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 3,
            }
          },
          {
            breakpoint: 763,
            settings: {
              slidesToShow: 2,
            }
          },
          {
            breakpoint: 550,
            settings: {
              slidesToShow: 1,
            }
          },
        ]
    };
      
  return (
    <>
    <Slider {...settings} className='main-slider'>
      <Suspense fallback={<div>Loading...</div>}>
        <Video source={video1} alt="testimonial-video-1" />
      </Suspense>
      <Suspense fallback={<div>Loading...</div>}>
        <Video source={video2} alt="testimonial-video-2" />
      </Suspense>
      <Suspense fallback={<div>Loading...</div>}>
        <Video source={video3} alt="testimonial-video-3" />
      </Suspense>
      <Suspense fallback={<div>Loading...</div>}>
        <Video source={video4} alt="testimonial-video-4" />
      </Suspense>
      <Suspense fallback={<div>Loading...</div>}>
        <Video source={video5} alt="testimonial-video-5" />
      </Suspense>
      <Suspense fallback={<div>Loading...</div>}>
        <Video source={video6} alt="testimonial-video-6" />
      </Suspense>
      <Suspense fallback={<div>Loading...</div>}>
        <Video source={video7} alt="testimonial-video-7" />
      </Suspense>
      <Suspense fallback={<div>Loading...</div>}>
        <Video source={video8} alt="testimonial-video-8" />
      </Suspense>
      <Suspense fallback={<div>Loading...</div>}>
        <Video source={video9} alt="testimonial-video-9" />
      </Suspense>
      <Suspense fallback={<div>Loading...</div>}>
        <Video source={video10} alt="testimonial-video-10" />
      </Suspense>
      <Suspense fallback={<div>Loading...</div>}>
        <Video source={video11} alt="testimonial-video-11" />
      </Suspense>
      <Suspense fallback={<div>Loading...</div>}>
        <Video source={video12} alt="testimonial-video-12" />
      </Suspense>
      <Suspense fallback={<div>Loading...</div>}>
        <Video source={video13} alt="testimonial-video-13" />
      </Suspense>
      <Suspense fallback={<div>Loading...</div>}>
        <Video source={video14} alt="testimonial-video-14" />
      </Suspense>
    </Slider>
    </>
  )
}
