import { useRef } from "react"
import { useFrame } from "@react-three/fiber"

export default function Shapes ({ isMobile }){
    const shpereRef = useRef()
    const coneRef = useRef()
    const torusRef = useRef()

    useFrame(({ state, delta })=>{
        const random = Math.random()

        shpereRef.current.rotation.x +=  1 * 0.002
        shpereRef.current.rotation.y += 1 * 0.002

        coneRef.current.rotation.x +=  -1 * 0.002
        coneRef.current.rotation.y += -1 * 0.002

        torusRef.current.rotation.x +=  2 * 0.002
        torusRef.current.rotation.y += 2 * 0.002
    })

    return(
        <group position={[isMobile? 2.2: 4.5, -2.5, 0]}>
            <mesh position={[0, -0.5, 0]} ref={torusRef}>
                <torusGeometry args={[0.2, 0.05, 16, 38, 6.4]} />
                <meshStandardMaterial metalness={0.5} roughness={0.1} color='#ffff00' />
            </mesh>

            <mesh position={[0.5, 0.5, 0]} ref={coneRef}>
                <coneGeometry args={[0.2, 0.2, 3]} />
                <meshStandardMaterial metalness={0.5} roughness={0.1} color='#ab00ff' />
            </mesh>

            <mesh position={[-0.5, 0, 0]} ref={shpereRef}>
                <sphereGeometry args={[0.1, 32, 32]} />
                <meshStandardMaterial metalness={0.5} roughness={0.1} color='#ffab00' />
            </mesh>
        </group>
    )
}