import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";

export function SolutionModel(props) {
  const { nodes, materials } = useGLTF("/solution.gltf");

  const ref = useRef()

  useFrame(({ state, delta })=>{
    const shpere = ref.current
    const random = Math.random()

    shpere.rotation.x +=  random * 0.005
    shpere.rotation.y += random * 0.005
  })

  return (
    <group {...props} dispose={null} >
      <group 
        ref={ref}
        position={[0, 2.332, 0]}
        rotation={[1.539, 0.033, -1.126]}
        scale={1.034}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Icosphere.geometry}
          material={materials.Blue}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Icosphere_1.geometry}
          material={materials["Blue Light"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Icosphere_2.geometry}
          material={materials.Green}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Icosphere_3.geometry}
          material={materials.Orange}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Icosphere_4.geometry}
          material={materials.Purple}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Icosphere_5.geometry}
          material={materials.Red}
        />
      </group>
      {/* <mesh
        castShadow
        receiveShadow
        geometry={nodes.Sphere.geometry}
        material={materials.Black}
        position={[0.112, 2.368, 1.027]}
        scale={0.115}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Sphere001.geometry}
        material={materials.Black}
        position={[0.594, 2.695, 0.764]}
        scale={0.115}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Sphere002.geometry}
        material={materials.Black}
        position={[0.078, 2.906, 0.857]}
        scale={0.115}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Sphere003.geometry}
        material={materials.Black}
        position={[0.113, 1.82, 0.891]}
        scale={0.115}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Sphere004.geometry}
        material={materials.Black}
        position={[-0.413, 2.027, 0.898]}
        scale={0.115}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Sphere005.geometry}
        material={materials.Black}
        position={[-0.433, 2.665, 0.878]}
        scale={0.115}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Sphere006.geometry}
        material={materials.Black}
        position={[0.615, 2.057, 0.785]}
        scale={0.115}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Sphere007.geometry}
        material={materials.Black}
        position={[0.401, 1.524, 0.505]}
        scale={0.115}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Sphere008.geometry}
        material={materials.Black}
        position={[-0.234, 1.505, 0.575]}
        scale={0.115}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Sphere009.geometry}
        material={materials.Black}
        position={[-0.78, 1.802, 0.425]}
        scale={0.115}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Sphere010.geometry}
        material={materials.Black}
        position={[-0.815, 2.325, 0.636]}
        scale={0.115}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Sphere011.geometry}
        material={materials.Black}
        position={[-0.289, 3.176, 0.522]}
        scale={0.115}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Sphere012.geometry}
        material={materials.Black}
        position={[0.346, 3.195, 0.452]}
        scale={0.115}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Sphere013.geometry}
        material={materials.Black}
        position={[-0.034, 3.365, -0.033]}
        scale={0.115}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Sphere014.geometry}
        material={materials.Black}
        position={[0.511, 3.226, -0.087]}
        scale={0.115}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Sphere015.geometry}
        material={materials.Black}
        position={[-0.569, 3.195, 0.031]}
        scale={0.115}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Sphere016.geometry}
        material={materials.Black}
        position={[-0.813, 2.835, 0.393]}
        scale={0.115}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Sphere017.geometry}
        material={materials.Black}
        position={[0.849, 2.884, 0.209]}
        scale={0.115}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Sphere018.geometry}
        material={materials.Black}
        position={[-1.027, 2.302, 0.113]}
        scale={0.115}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Sphere019.geometry}
        material={materials.Black}
        position={[0.933, 2.376, 0.444]}
        scale={0.115}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Sphere020.geometry}
        material={materials.Black}
        position={[1.027, 2.362, -0.113]}
        scale={0.115}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Sphere021.geometry}
        material={materials.Black}
        position={[-0.849, 1.78, -0.209]}
        scale={0.115}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Sphere022.geometry}
        material={materials.Black}
        position={[-0.511, 1.437, 0.087]}
        scale={0.115}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Sphere023.geometry}
        material={materials.Black}
        position={[0.034, 1.299, 0.033]}
        scale={0.115}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Sphere024.geometry}
        material={materials.Black}
        position={[0.569, 1.469, -0.031]}
        scale={0.115}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Sphere025.geometry}
        material={materials.Black}
        position={[0.883, 1.851, 0.242]}
        scale={0.115}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Sphere026.geometry}
        material={materials.Black}
        position={[-0.112, 2.295, -1.027]}
        scale={0.115}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Sphere027.geometry}
        material={materials.Black}
        position={[0.413, 2.636, -0.898]}
        scale={0.115}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Sphere028.geometry}
        material={materials.Black}
        position={[-0.113, 2.844, -0.891]}
        scale={0.115}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Sphere029.geometry}
        material={materials.Black}
        position={[0.234, 3.159, -0.575]}
        scale={0.115}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Sphere030.geometry}
        material={materials.Black}
        position={[0.78, 2.861, -0.425]}
        scale={0.115}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Sphere031.geometry}
        material={materials.Black}
        position={[0.815, 2.338, -0.636]}
        scale={0.115}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Sphere032.geometry}
        material={materials.Black}
        position={[-0.615, 2.607, -0.785]}
        scale={0.115}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Sphere033.geometry}
        material={materials.Black}
        position={[-0.401, 3.14, -0.505]}
        scale={0.115}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Sphere034.geometry}
        material={materials.Black}
        position={[-0.883, 2.813, -0.242]}
        scale={0.115}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Sphere035.geometry}
        material={materials.Black}
        position={[0.813, 1.828, -0.393]}
        scale={0.115}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Sphere036.geometry}
        material={materials.Black}
        position={[0.433, 1.998, -0.878]}
        scale={0.115}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Sphere037.geometry}
        material={materials.Black}
        position={[-0.933, 2.287, -0.444]}
        scale={0.115}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Sphere038.geometry}
        material={materials.Black}
        position={[-0.594, 1.968, -0.764]}
        scale={0.115}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Sphere039.geometry}
        material={materials.Black}
        position={[-0.078, 1.758, -0.857]}
        scale={0.115}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Sphere040.geometry}
        material={materials.Black}
        position={[-0.346, 1.469, -0.452]}
        scale={0.115}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Sphere041.geometry}
        material={materials.Black}
        position={[0.289, 1.487, -0.522]}
        scale={0.115}
      /> */}
      {/* <mesh
        castShadow
        receiveShadow
        geometry={nodes.Text.geometry}
        material={materials.White}
        position={[-5.547, 2.392, 0.002]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={1.504}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Text001.geometry}
        material={materials.White}
        position={[6.551, 2.392, 0.002]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={1.504}
      /> */}
    </group>
  );
}

useGLTF.preload("/solution.gltf");
