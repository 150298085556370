import { useRef, useEffect, useState } from "react";
import { useThree, useFrame } from "@react-three/fiber";
import gsap from "gsap";

export default function CameraMesh ({ isMobile }) {
    const meshRef = useRef();
    const { camera } = useThree();

    const [offset, setOffset] = useState( window.innerWidth < 480? 0.5 : 0)
    const [zoomFactor, setZoomFactor] = useState(0.0)

    const handleScroll = (event) => {
        // Adjust the zoom factor based on the scroll event
        let scrolled = window.scrollY / window.innerHeight
        setZoomFactor(scrolled)
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
    
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
    }, [zoomFactor]);

    useFrame(() => {        
        if (zoomFactor >= 0 && zoomFactor <= 5.8){
            // Update the camera position based on the zoom factor
            gsap.to(camera.position, { x: 1.5, y: -4 +zoomFactor, z: 2 -zoomFactor, duration:1 })
            gsap.to(camera.rotation, { z: 0, duration:1 })
        }
        if (zoomFactor > 5.8 && zoomFactor <= 6.8) {
            gsap.to(camera.position, { y: -4, z: 2, duration:1 })
            gsap.to(camera.rotation, { z: 1, duration:1 })
        } else if (zoomFactor > 6.8) {
            gsap.to(camera.position, { y: -4, z: 1, duration:1 })
            gsap.to(camera.rotation, { z: 2, duration:1 })
        }
    })

    return (<mesh ref={meshRef}></mesh>)
} 