import { useEffect, useRef } from "react"
import { useFrame } from "@react-three/fiber"
import gsap from "gsap"

export default function HeroIcosahedron ({ position, args, rotation }){
    const ref = useRef()

    useFrame(({ state, delta })=>{
        const shpere = ref.current
        const random = Math.random()

        shpere.rotation.x +=  rotation.x * 0.002
        shpere.rotation.y += rotation.y * 0.002
    })

    useEffect(()=>{
        if(ref.current){
            gsap.to(ref.current.position, { x: position[0], y: position[1], z: position[2], duration: 1, ease: "elastic.out(1,0.3)" })
        }
    }, [ref])

    return(
        <mesh position={[0, 0, 0]} ref={ref}>
            <icosahedronGeometry args={args} />
            {/* <sphereGeometry args={args} /> */}
            <meshStandardMaterial color='#010256' flatShading metalness={0.5} roughness={0.2} />
        </mesh>
    )
}