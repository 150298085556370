import React, { useEffect } from 'react'
import TestimonialsSlider from './sections/TestimonialsSlider';
import Agency from './sections/Agency';
import SprintYourWayToSuccess from './sections/SprintYourWayToSuccess';
import FutureInnovation from './sections/FutureInnovation';
import Testimonials from './sections/Testimonials';
import TechsprintLeadershipRecruitmentAgency from './sections/TechsprintLeadershipRecruitmentAgency';
import WhyGTLRA from './sections/WhyGTLRA';
import Experience from '../three/Experience';

export default function Gtlra({ isMobile }) {
  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top when the component mounts
  }, []);

  return (
    <>
    <Experience isMobile={isMobile} />
    <SprintYourWayToSuccess />
    <TechsprintLeadershipRecruitmentAgency />
    <Agency />
    <WhyGTLRA />
    {/* <FutureInnovation /> */}
    <Testimonials />
    </>
  )
}
