import { Canvas } from "@react-three/fiber"
import * as THREE from 'three'

import { SolutionModel } from "./solutionModel"
import HeroIcosahedron from "./HeroIcosahedron"
import CameraMesh from "./CameraMesh"
import Effects from "./Effects"
import Shapes from "./Shapes"
 
THREE.ColorManagement.legacyMode = false

export default function Experience ({ isMobile, scrollY}){
    return(
        <div style={{ position: 'fixed', width: '100%', height: '100vh', top: '0', left: '0', zIndex: '-1' }}>
        <Canvas 
            orthographic camera={{ zoom: 150, position:[0, -4, 2] }}
            onCreated={(state) => (state.gl.toneMappingExposure = 1.3)}
        >
            {/* <color attach="background" args={['#010213']} /> */}

            <CameraMesh isMobile={isMobile} />

            <group position={[3, 6.5, -4]}>
                <SolutionModel />
            </group>

            <Shapes isMobile={isMobile} />

            {/* <HeroIcosahedron position={[3, -2, -2]} args={[2.5, 1]} rotation={{x: -1, y: 1}} /> */}
            <HeroIcosahedron position={[2, 8, -2]} args={[1, 1]} rotation={{x: 1, y: -1}} />
            {/* <HeroIcosahedron position={[4, 2, -2]} args={[1.5, 1]} rotation={{x: -1, y: -1}} /> */}
            <HeroIcosahedron position={[-4, 1, -1]} args={[2, 1]} rotation={{x: 1, y: 1}} />

            <hemisphereLight intensity={1.1} />
            <directionalLight position={[0,1,1]} intensity={2} color='#ffffff' shadow-mapSize={[1024, 1024]} />
            {/* <Effects /> */}
        </Canvas>
        </div>
    )
}